import React from 'react'

const AppointmentConfirmation = () => {
  return (
    <>
        <div className="driving-test">
            <div className="container-fluid">
                <div className="filter row">
                    <div className="col-lg-9 col-md-8">
                        <div className="row g-2 align-items-center">
                            <div className="col-lg-3 col-md-3">
                                <span>Confirmation Appointments For:</span>
                            </div>
                            <div className="col-lg-2 col-md-3">
                                <select name="dopdown-btn" id="dopdown-btn" className="form-control dopdown-btn">
                                    <option value="December">December</option>
                                    <option value="January">January</option>
                                    <option value="February">February</option>
                                    <option value="March">March</option>
                                </select> 
                            </div>
                            <div className="col-lg-2 col-md-3">
                                <select name="dopdown-btn" id="dopdown-btn" className="form-control dopdown-btn">
                                    <option value="10">10</option>
                                    <option value="11">11</option>
                                    <option value="12">12</option>
                                    <option value="13">13</option>
                                    <option value="14">14</option>
                                    <option value="15">15</option>
                                    <option value="16">16</option>
                                </select> 
                            </div>
                            <div className="col-lg-2 col-md-3">
                                <select name="dopdown-btn" id="dopdown-btn" className="form-control dopdown-btn">
                                    <option value="2001">10</option>
                                    <option value="2002">11</option>
                                    <option value="2003">12</option>
                                    <option value="2004">13</option>
                                    <option value="2005">14</option>
                                    <option value="2015">15</option>
                                    <option value="2016">16</option>
                                </select>
                            </div>
                            <div className="col-lg-2 col-md-2">
                                <span className="btn btn-go">Go</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                        <div className="float-end">
                            <div className="btn btn-primary">
                                <span className="">Submit</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="custom-table table-responsive">
                <table className="table">
                    <thead>
                        <tr>
                            <th>No.</th>
                            <th>NAME</th>
                            <th>EMAIL</th>
                            <th>PHONE</th>
                            <th>PARENT PHONE NUMBER</th>
                            <th>PICKUP</th>
                            <th>PICKUP TEXT</th>
                            <th>TIME IN</th>
                            <th className="text-center">TIME OUT</th>
                            <th>STATUS</th>
                            <th>CONFIRMED</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>1</td>
                            <td>CHEYENNE DEVINE/SUREPASS</td>
                            <td>CHEYENNE.DEVINE@SUPEPASS.COM</td>
                            <td>8056577659</td>
                            <td>8052086081</td>
                            <td>HOME</td>
                            <td>TO DMV Confirmation#;238954989</td>
                            <td>9:30 am</td>
                            <td className="text-center">11:30 am</td>
                            <td>11:30 am</td>
                            <td>Method: manually Tosh-Confirmed With dad-2023-12-14 18:18:35</td>
                        </tr>
                        <tr>
                            <td>2</td>
                            <td>CHEYENNE DEVINE/SUREPASS</td>
                            <td>CHEYENNE.DEVINE@SUPEPASS.COM</td>
                            <td>8056577659</td>
                            <td>8052086081</td>
                            <td>HOME</td>
                            <td>TO DMV Confirmation#;238954989</td>
                            <td>9:30 am</td>
                            <td className="text-center">11:30 am</td>
                            <td>11:30 am</td>
                            <td><input type="text" className="text-box"/></td>
                        </tr>
                        <tr>
                            <td>1</td>
                            <td>CHEYENNE DEVINE/SUREPASS</td>
                            <td>CHEYENNE.DEVINE@SUPEPASS.COM</td>
                            <td>8056577659</td>
                            <td>8052086081</td>
                            <td>HOME</td>
                            <td>TO DMV Confirmation#;238954989</td>
                            <td>9:30 am</td>
                            <td className="text-center">11:30 am</td>
                            <td>11:30 am</td>
                            <td>Method: manually Tosh-Confirmed With dad-2023-12-14 18:18:35</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </>
  )
}
export default AppointmentConfirmation;
