import React, { useState,useEffect } from 'react';
import { Link, useLocation } from "react-router-dom";

export default function Header() {
    const location = useLocation(); 
    const [pageTitle, setPageTitle] = useState("User List");
    useEffect(() => {
        if(location.pathname.includes("/")){
            setPageTitle('User List')
        }
        if(location.pathname.includes("/appointment-confirmation")){
            setPageTitle('Driving Test Appointtment Confirmation')
        }
        if(location.pathname.includes("/messages")){
            setPageTitle('Messages')
        }
            
        if(location.pathname.includes("/certificates")){
            setPageTitle('Certificates')
        }
        if(location.pathname.includes("/accounting")){
            setPageTitle('Accounting')
        }
        if(location.pathname.includes("/instructor")){
            setPageTitle('Instructor')
        }
    
    }, [location]);
  return (
    <header>
      <span className="page-title">{pageTitle}</span>
      <div className="d-flex">
          <div className="notification m-1">
              <i className="sfds-notifications"></i>
          </div>

          <div className="dropdown">
              <button className="btn dropdown-toggle profile-btn" type="button" data-bs-toggle="dropdown" aria-expanded="false">
              SF
              </button>
              <ul className="dropdown-menu">
                  <li>
                      <a className="dropdown-item" href="{{route('myprofile')}} ">
                          <i className="sfds-Profile"></i>
                              Your Profile
                      </a>
                  </li>
                  <li>
                      <a className="dropdown-item" href="#">
                          <i className="sfds-Logout"></i>
                          Log Out
                      </a>
                  </li>
              </ul>
          </div>
      </div>
  </header> 
  )
}
