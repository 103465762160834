import React, { useState } from 'react';

const Accounting = () => {
  const [searchHistory, setSearchHistory] = useState("");
  const [filterStatus, setFilterStatus] = useState("name");
  const [dateFilter, setDateFilter] = useState("2024-02-14");
  const [selectedTransaction, setSelectedTransaction] = useState(1);

  const handleSearchHistoryChange = (event) => {
    setSearchHistory(event.target.value);
  };

  const handleFilterStatusChange = (event) => {
    setFilterStatus(event.target.value);
  };

  const handleDateFilterChange = (event) => {
    setDateFilter(event.target.value);
  };

  const handleClearFilter = () => {
    // Add logic for clearing filter
    console.log("Clearing Filter");
  };

  const handleTransactionChange = (value) => {
    setSelectedTransaction(value);
  };

  return (
    <>
      <div className="accunting-page">
        <div className="container-fluid">
          <div className="row mt-3">
            <div className="col-lg-6 col-md-6">
              <b className="accounting-filter">Statements and invoicing</b>
              <p className="accounting-text">Your Payment and Subscription details.</p>
            </div>
            <div className="col-lg-6 col-md-6">
              <div className="accountingpage-btn">
                <button className="btn btn-black py-1"><i className="sfds-download"></i> Download</button>
                <button className="btn btn-primary py-1">Add New Transaction  +</button>
              </div>
            </div>
          </div>
          <div className="filter row mt-3">
            <div className="col-lg-3 leftside-tabing">
              <div className="nav flex-column nav-pills me-3" id="accounting-tab" role="tablist" aria-orientation="vertical">
                <button
                  className={`tab-buttton ${selectedTransaction === 1 ? 'active' : ''}`}
                  onClick={() => handleTransactionChange(1)}
                >
                  <div className="">
                    <i className="sfds-Rupees"></i>
                    <span>Transactions</span>
                  </div>
                </button>
                <button
                  className={`tab-buttton ${selectedTransaction === 2 ? 'active' : ''}`}
                  onClick={() => handleTransactionChange(2)}
                >
                  <div className="">
                    <i className="sfds-activities"></i> Activities
                  </div>
                </button>
                <button
                  className={`tab-buttton ${selectedTransaction === 3 ? 'active' : ''}`}
                  onClick={() => handleTransactionChange(3)}
                >
                  <div className="text-start">
                    <i className="sfds-car"></i> Driver Training & Education
                  </div>
                </button>
              </div>
            </div>
            <div className="col-lg-9">
              <div className="tab-content" id="accounting-tabContent">
                {selectedTransaction === 1 && (
                  <div className={`tab-pane fade show active`} id="v-pills-Transactions" role="tabpanel" aria-labelledby="Transactions-tab" tabIndex="0">

                    <div className="row">
                        <div className="col-lg-2 col-md-3">
                            <div className="search-box">
                            <input type="search" className="pe-5 form-control" placeholder="Search History" value={searchHistory} onChange={handleSearchHistoryChange} />
                                <i className="sfds-search"></i>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-3">
                            <select
                                    name="dopdown-btn"
                                    id="dopdown-btn"
                                    className="form-control dopdown-btn"
                                    value={filterStatus}
                                    onChange={handleFilterStatusChange}
                                >
                                <option value="name">Filter by status</option>
                                <option value="name">name</option>
                                <option value="date">date</option>
                                <option value="birthday">birthday</option>
                            </select> 
                        </div>
                        <div className="col-lg-2 col-md-3">
                            <input className="form-control date-picker" type="date" value={dateFilter} onChange={handleDateFilterChange} />
                        </div>
                        <div className="col-lg-2 col-md-2">
                            <button className="btn-whiteoutline" onClick={handleClearFilter}>
                                Clear Filter
                            </button>
                        </div>
                    </div>
                    <div className="custom-table table-responsive">
                        <table className="table">
                            <thead>
                            <tr>
                                <th className="text-center">No.</th>
                                <th>NAME</th>
                                <th>AMOUNT</th>
                                <th>DISCOUNT AMOUNT</th>
                                <th className="text-center">TRANSACTION TYPE</th>
                                <th width="160">METHOD</th>
                                <th width="180">LOCATION</th>
                                <th>DATE CHARGED</th>
                                <th>REFUND</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td className="text-center">1</td>
                                <td>Jack Murphy</td>
                                <td>#12345</td>
                                <td>$2345</td>
                                <td className="text-center"><span className="right-btn"><i className="sfds-right-1 pe-1"></i>Purchase</span></td>
                                <td className="text-center">Cash</td>
                                <td>Telephone</td>
                                <td>12.19.2023</td>
                                <td className="view-button">View</td>
                            </tr>
                            <tr>
                                <td className="text-center">2</td>
                                <td>Jack Murphy</td>
                                <td>#12345</td>
                                <td>$2345</td>
                                <td className="text-center"><span className="close-btn"><i className="sfds-close-1 pe-1"></i>Not Purchase</span></td>
                                <td className="text-center">Cash</td>
                                <td>Telephone</td>
                                <td>12.19.2023</td>
                                <td className="view-button">View</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                  </div>
                )}
                {selectedTransaction === 2 && (
                  <div className={`tab-pane fade show active`} id="v-pills-Activities" role="tabpanel" aria-labelledby="Activities-tab" tabIndex="0">
                        <div className="row">
                            <div className="col-lg-2 col-md-3">
                                <div className="search-box">
                                    <input type="search" className="pe-5 form-control" placeholder="Search History" value={searchHistory} onChange={handleSearchHistoryChange} />
                                    <i className="sfds-search"></i>
                                </div>
                            </div>
                            <div className="col-lg-2 col-md-3">
                                <select name="dopdown-btn" id="dopdown-btn" className="form-control dopdown-btn">
                                    <option value="name">Filter by status</option>
                                    <option value="name">name</option>
                                    <option value="date">date</option>
                                    <option value="birthday">birthday</option>
                                </select> 
                            </div>
                            <div className="col-lg-2 col-md-3">
                                <input className="form-control date-picker" type="date" value={dateFilter} onChange={handleDateFilterChange} />
                            </div>
                            <div className="col-lg-2 col-md-2">
                                <button className="btn-whiteoutline" onClick={handleClearFilter}>
                                    Clear Filter
                                </button>
                            </div>
                        </div>

                        <div className="custom-table table-responsive">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th className="text-center">ID</th>
                                        <th>NAME</th>
                                        <th>EMAIL</th>
                                        <th>WHEN</th>
                                        <th>ACTION</th>
                                        <th className="text-center">DESCRIPTION</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className="text-center">1</td>
                                        <td>Jack Murphy</td>
                                        <td>amurph66@gmail.com</td>
                                        <td>12/14/2023 6:00 PM (PST)</td>
                                        <td className="btn-purple">Logged in</td>
                                        <td className="text-center">user logged in via a chrome browser</td>
                                    </tr>
                                    <tr>
                                        <td className="text-center">1</td>
                                        <td>Jack Murphy</td>
                                        <td>amurph66@gmail.com</td>
                                        <td>12/14/2023 6:00 PM (PST)</td>
                                        <td className="btn-red">Logged out</td>
                                        <td className="text-center">user logged in via a chrome browser</td>
                                    </tr>
                                    <tr>
                                        <td className="text-center">1</td>
                                        <td>Jack Murphy</td>
                                        <td>amurph66@gmail.com</td>
                                        <td>12/14/2023 6:00 PM (PST)</td>
                                        <td className="btn-orange">Profile updated</td>
                                        <td className="text-center">user logged in via a chrome browser</td>
                                    </tr>
                                    <tr>
                                        <td className="text-center">1</td>
                                        <td>Jack Murphy</td>
                                        <td>amurph66@gmail.com</td>
                                        <td>12/14/2023 6:00 PM (PST)</td>
                                        <td className="btn-green">Subscribed Course</td>
                                        <td className="text-center">user logged in via a chrome browser</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div> 


                  </div>
                )}
                {selectedTransaction === 3 && (
                  <div className={`tab-pane fade show active`} id="v-pills-DriverTraining" role="tabpanel" aria-labelledby="DriverTraining-tab" tabIndex="0">
                        <div className="row">
                            <div className="col-lg-2 col-md-3">
                                <div className="search-box">
                                    <input className="form-control date-picker" type="date" value={dateFilter} onChange={handleDateFilterChange} />
                                    <i className="sfds-search"></i>
                                </div>
                            </div>
                            <div className="col-lg-2">
                                <select name="dopdown-btn" id="dopdown-btn" className="form-control dopdown-btn">
                                    <option value="name">Filter by status</option>
                                    <option value="name">name</option>
                                    <option value="date">date</option>
                                    <option value="birthday">birthday</option>
                                </select> 
                            </div>
                            <div className="col-lg-2">
                                <input className="form-control date-picker" type="date" value={dateFilter} onChange={handleDateFilterChange} />
                            </div>
                            <span className="col-lg-2 information-filter">Clear Filter</span>
                        </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Accounting;

