import './App.css';
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Sidebar from "./components/Sidebar";
import Header from "./components/Header";
import UserList from './pages/UserList';
import AppointmentConfirmation from './pages/AppointmentConfirmation';
import Messages from './pages/Messages';
import Certificates from './pages/Certificates';
import Accounting from './pages/Accounting';
import Instructor from './pages/Instructor';



function App() {
  return (
    <Routes>
      <Route 
        path='/'
        // path='/user-list'
        element={
          <>
            <Sidebar/>
            <div class="page-content">
                <Header/>
                <UserList/>
            </div>
          </>
      }/>

      <Route 
        path='/appointment-confirmation'
        element={
          <>
            <Sidebar/>
            <div class="page-content">
                <Header/>
                <AppointmentConfirmation/>
            </div>
          </>
      }/>

      <Route 
        path='/messages'
        element={
          <>
            <Sidebar/>
            <div class="page-content">
                <Header/>
                <Messages/>
            </div>
          </>
      }/>

      <Route 
        path='/certificates'
        element={
          <>
            <Sidebar/>
            <div class="page-content">
                <Header/>
                <Certificates/>
            </div>
          </>
      }/>

      <Route 
        path='/accounting'
        element={
          <>
            <Sidebar/>
            <div class="page-content">
                <Header/>
                <Accounting/>
            </div>
          </>
      }/>

      <Route 
        path='/instructor'
        element={
          <>
            <Sidebar/>
            <div class="page-content">
                <Header/>
                <Instructor/>
            </div>
          </>
      }/>

    </Routes>
  );
}

export default App;
