import React from 'react'

const Instructor = () => {
  return (
    <div>
      {/* Instructor */}
    </div>
  )
}
export default Instructor;
