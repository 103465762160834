import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

export default function Sidebar() {
  return (
      <div className="leftsidebar">
            <a className="sfds-angle-double-right mb-2"></a>
            <Link className="sfds-address-book" to="/"></Link>
            <Link className="sfds-calendar-check-o" to="/appointment-confirmation"></Link>
            <Link className="sfds-chat" to="/messages"></Link>
            <Link className="sfds-check-circle" to="/certificates"></Link>
            <Link className="sfds-wallet" to="/accounting"></Link>
            <Link className="sfds-instructor" to="/instructor"></Link>
            {/* <a href="{{route('userlists')}}" className="sfds-address-book {{ Request::is('user-list') ? 'active' : ''}}"></a>
            <a href="{{route('appointmentconf')}}" className="sfds-calendar-check-o {{ Request::is('appointment-confirmation') ? 'active' : ''}}"></a>
            <a href="{{route('messages')}}" className="sfds-chat {{ Request::is('messages') ? 'active' : ''}}"></a>
            <a href="{{route('certificates')}}" className="sfds-check-circle {{ Request::is('certificates') ? 'active' : ''}}"></a>
            <a href="{{route('accounting')}}" className="sfds-wallet {{ Request::is('accounting') ? 'active' : ''}}"></a>
            <a href="{{route('instructor')}}" className="sfds-instructor {{ Request::is('instructor') ? 'active' : ''}}"></a> */}
        </div>
  )
}
