import React from 'react'

const Messages = () => {
  return (
    <>
        <div className="container-fluid">           
            <div className="message-page">
                <div className="row">
                    <div className="col-lg-4 col-md-4">
                        <div className="message-list">
                            <span className="message">Message</span>
                            <div className="search-button">
                                <span>Search...</span>
                                <i className="sfds-search"></i>
                            </div>
                            <div className="chat-list row">
                                <div className="chat-img-active col-lg-1 col-md-1 col-sm-1">
                                    <img src="../images/man.png" alt=""/>
                                </div>
                                <div className="chat-name col-lg-9 col-md-9 col-sm-9">
                                    Janet Adebayo<p className="typing">Typing.....</p>
                                </div>
                                <div className="rightside-chat col-lg-2 col-md-2 col-sm-2">
                                    <span></span>
                                    <p>14:31am</p>
                                </div>
                            </div>
                            <div className="chat-list row">
                                <div className="chat-img-anactive col-lg-1 col-md-1">
                                    <img src="../images/man.png" alt=""/>
                                </div>
                                <div className="chat-name col-lg-9 col-md-9 ps-4">
                                    Janet Adebayo<p>Wow really Cool</p>
                                </div>
                                <div className="rightside-chat col-lg-2 col-md-2">
                                    <span className="message-notification">2</span>
                                    <p>14:31am</p>
                                </div>
                            </div>
                            <div className="chat-list row">
                                <div className="chat-img-anactive col-lg-1 col-md-1">
                                    <img src="../images/man.png" alt=""/>
                                </div>
                                <div className="chat-name col-lg-9 col-md-9 ps-4">
                                    Janet Adebayo<p>Wow really Cool</p>
                                </div>
                                <div className="rightside-chat col-lg-2 col-md-2">
                                    <span className="newmessage-notification">new</span>
                                    <p>14:31am</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-8 col-md-8"> 
                        <div className="profilemessage-page">
                            <div className="chat-page">
                                <div className="chat-img me-3">
                                    <img src="../images/man.png" alt=""/>
                                </div>
                                <div className="align-self-center">
                                    <span>Jack Murphy</span>
                                    <p>Online</p>
                                </div>
                            </div>
                            <div className="chat-message mt-4">
                                <p>11:30am</p>
                                <div className="sms">
                                    <span>Hi How Are You Doing?..</span><i className="sfds-ellipsis-v"></i>
                                </div>
                            </div>
                            <div className="chat-message mt-4">
                                <div className="sms">
                                    <span>Would Love To Learn For You</span><i className="sfds-ellipsis-v"></i>
                                </div>
                            </div>
                            <div className="chat-date mt-3">
                                <span>12August2023</span>
                            </div>
                            <div className="chat-message mt-4">
                                <p>12:30am</p>
                                <div className="sms">
                                    <span>Hey Happy To hear you.Yes,i will be back in a couple for days.</span><i className="sfds-ellipsis-v"></i>
                                </div>
                            </div>
                            <div className="chat-message mt-4">
                                <div className="sms">
                                    <span>Here are Some of My Documents</span><i className="sfds-ellipsis-v"></i>
                                </div>
                            </div>
                            <div className="chat-message mt-4">
                                <p>2:30am</p>
                                <img className="chat-img" src="../images/chat_img.png" alt=""/>
                            </div>
                            <div className="reply-message my-4">
                                <span>Here are Some of My Documents</span>
                            </div>
                            <div className="search-button d-flex justify-content-between">
                                <div>
                                    <i className="sfds-microphone pe-2"></i>
                                    <span>Add a comment...</span>
                                </div>
                                <div>
                                    <i className="sfds-picture-o pe-2"></i>
                                    <i className="sfds-smile-o pe-2"></i>
                                    <i className="sfds-map-marker pe-2"></i>
                                    <i className="sfds-location-arrow pe-2"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>    
            </div>
        </div>
    </>
  )
}

export default Messages
