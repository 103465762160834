import React, { useState } from 'react';

const Certificates = () => {
  // Dummy state for input fields
  const [certificateNumber, setCertificateNumber] = useState("");
  const [dateValue, setDateValue] = useState("");
  const [selectedOption, setSelectedOption] = useState("choose");
  const [selectedTransaction, setSelectedTransaction] = useState(1);

  // Dummy handler functions
  const handleCertificateNumberChange = (event) => {
    setCertificateNumber(event.target.value);
  };

  const handleDateValueChange = (event) => {
    setDateValue(event.target.value);
  };

  const handleDropdownChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleSubmit = () => {
    // Dummy submit function, you can add logic here
    console.log("Certificate Number:", certificateNumber);
    console.log("Date Value:", dateValue);
    console.log("Selected Option:", selectedOption);
    // Add more logic as needed
  };

  const handleTransactionChange = (value) => {
    setSelectedTransaction(value);
  };

  return (
    <>
      <div className="container-fluid">
        <div className="certificate">
          <ul className="nav mb-3 gold-certificate" id="pills-tab" role="tablist">
            <li>
              <ul className="certificate-both">

                <li className="list-unstyled align-self-center" role="presentation">
                  <button className={`tab-page ${selectedTransaction === 1 ? 'active' : ''}`}
                  onClick={() => handleTransactionChange(1)} id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected={`${selectedTransaction === 1 ? 'true' : 'false'}`}>
                    <img src="../images/gold.png" alt="Gold Certificate" />
                    Golden certificate
                  </button>
                </li>

                <li className="list-unstyled align-self-center" role="presentation">
                  <button lassName={`tab-page ${selectedTransaction === 2 ? 'pink-button' : ''}`}
                  onClick={() => handleTransactionChange(2)} id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected={`${selectedTransaction === 2 ? 'true' : 'false'}`}>
                    <img src="../images/pink.png" alt="Pink Certificate" />
                    Pink certificate
                  </button>
                </li>
                
              </ul>
            </li>
          </ul>
          <div className="tab-content" id="pills-tab">
          {selectedTransaction === 1 && (
                <div className="certificate-table tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab" tabindex="0">
                <span>
                    Show Users Who have completed 6 drivers training hours in the next
                    <p className="gold-number">06<i className="sfds-down_arrow_black"></i></p> days.
                    <button className="btn btn-go" onClick={handleSubmit}>Go</button>
                </span>
                <div className="custom-table table-responsive">
                    <table className="table">
                    <thead>
                        <tr>
                        <th className="text-center">No.</th>
                        <th>NAME</th>
                        <th>EMAIL</th>
                        <th>PHONE</th>
                        <th>BIRTHDAY</th>
                        <th width="160">SCHEDULED HOURS AS Of 12-18-2023</th>
                        <th width="180">DRIVER TRAINING (GOLD) CERTIFICATE#</th>
                        <th>ISSUE DATE</th>
                        <th>INSTRUCTOR</th>
                        <th>ACTION</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                        <td className="text-center">1</td>
                        <td>Jack Murphy</td>
                        <td>amurph66@gmil.com</td>
                        <td>8055321902</td>
                        <td>01-01-2024</td>
                        <td className="text-center">6:00</td>
                        <td><input type="text" className="form-control text-box" value={certificateNumber} onChange={handleCertificateNumberChange} /></td>
                        <td><input className="form-control date-picker" type="date" value={dateValue} onChange={handleDateValueChange} /></td>
                        <td>
                            <select name="dropdown-btn" id="dropdown-btn" className="form-control dropdown-btn" value={selectedOption} onChange={handleDropdownChange}>
                            <option value="choose">Choose</option>
                            <option value="name">Name</option>
                            <option value="date">Date</option>
                            <option value="birthday">Birthday</option>
                            </select>
                        </td>
                        <td><button className="btn btn-secondary" onClick={handleSubmit}>Submit</button></td>
                        </tr>
                    </tbody>
                    </table>
                </div>
                </div>
            )}
            {selectedTransaction === 2 && (
                <div className={`tab-pane fade show active`} id="pills-profile" role="tabpanel" aria-labelledby="pills-contact-tab" tabIndex="0">
                <div className="custom-table table-responsive">
                  <table className="table">
                    <thead>
                      <tr>
                        <th className="text-center">No.</th>
                        <th>NAME</th>
                        <th>EMAIL</th>
                        <th>BIRTHDAY</th>
                        <th width="180">DRIVER TRAINING (GOLD) CERTIFICATE#</th>
                        <th>ISSUE DATE</th>
                        <th>INSTRUCTOR</th>
                        <th className="text-center">SIGNUP DATE</th>
                        <th>ACTION</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="text-center">1</td>
                        <td>Jack Murphy</td>
                        <td>amurph66@gmil.com</td>
                        <td>01-01-2024</td>
                        <td><input type="text" className="form-control text-box" defaultValue="" /></td>
                        <td><input className="form-control date-picker" type="date" defaultValue="2024-03-22" /></td>
                        <td>
                          <select name="dopdown-btn" id="dopdown-btn" className="form-control dopdown-btn" defaultValue="">
                            <option value="name">choose</option>
                            <option value="name">name</option>
                            <option value="date">date</option>
                            <option value="birthday">birthday</option>
                          </select>
                        </td>
                        <td className="text-center">14/02/2023</td>
                        <td><button className="btn btn-secondary">submit</button></td>
                      </tr>
                      <tr>
                        <td className="text-center">2</td>
                        <td>Jack Murphy</td>
                        <td>amurph66@gmil.com</td>
                        <td>01-01-2024</td>
                        <td><input type="text" className="form-control text-box" defaultValue="" /></td>
                        <td><input className="form-control date-picker" type="date" defaultValue="2024-02-14" /></td>
                        <td>
                          <select name="dopdown-btn" id="dopdown-btn" className="form-control dopdown-btn" defaultValue="">
                            <option value="name">choose</option>
                            <option value="name">name</option>
                            <option value="date">date</option>
                            <option value="birthday">birthday</option>
                          </select>
                        </td>
                        <td className="text-center">14/02/2023</td>
                        <td><button className="btn btn-secondary">submit</button></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              
            )}

          </div>
        </div>
      </div>
    </>
  );
};

export default Certificates;
