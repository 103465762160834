import React from 'react'

const UserList = () => {
  return (
    <>
      <div className="userlist">
        <div className="container-fluid">
            <div className="filter row">
                <div className="col-lg-9 col-md-8">
                    <div className="row g-2">
                        <div className="col-lg-2 col-md-3">
                            <div className="search-box">
                                <input type="search" className="form-control" placeholder="Name or Email"/>
                                <i className="sfds-search"></i>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-3">
                            <input type="search" className="form-control" placeholder="City"/>
                        </div>
                        <div className="col-lg-2 col-md-3">
                            <input type="search" className="form-control" placeholder="State"/>
                        </div>
                        <div className="col-lg-2 col-md-3">
                            <input type="search" className="form-control" placeholder="More Filter"/>
                        </div>
                        <div className="col-lg-2 col-md-2">
                            <button className="btn-whiteoutline">Clear All Filter</button>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 col-md-4">
                    <div className="row gy-3 justify-content-end">
                        <div className="rightside-button col-md-6">
                            <span className="date-btn">Sort by date</span>
                            <i className="sfds-arrow-drop-down"></i>
                        </div>
                        <div className="col-md-6">
                            <button className="btn btn-primary">New Student +</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>     
    <div className="custom-table table-responsive">
        <table className="table">
            <thead>
                <tr>
                    <th>No.</th>
                    <th>USER NAME</th>
                    <th>FIRST NAME</th>
                    <th>LAST NAME</th>
                    <th>PINK CERT#</th>
                    <th>GOLD CERT#</th>
                    <th>LOGIN DATE</th>
                    <th>CREATE DATE</th>
                    <th className="text-center">TRANSACTION</th>
                    <th className="text-center">CERTIFICATES</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>1</td>
                    <td>ghodasaratrushangi@gmail.com</td>
                    <td>HELEN</td>
                    <td>SUPERPASS(    USE THIS ONE)</td>
                    <td>-</td>
                    <td>-</td>
                    <td>14/02/2001</td>
                    <td>16/7/2022</td>
                    <td className="text-center">1</td>
                    <td className="text-center"><img src="../images/Column Title.png"></img></td>
                </tr>
                <tr>
                    <td>1</td>
                    <td>ghodasaratrushangi@gmail.com</td>
                    <td>HELEN</td>
                    <td>SUPERPASS(    USE THIS ONE)</td>
                    <td>IP22564</td>
                    <td>-</td>
                    <td>14/02/2001</td>
                    <td>16/7/2022</td>
                    <td className="text-center">1</td>
                    <td></td>
                </tr>
                <tr>
                    <td>1</td>
                    <td>ghodasaratrushangi@gmail.com</td>
                    <td>HELEN</td>
                    <td>SUPERPASS(    USE THIS ONE)</td>
                    <td>-</td>
                    <td>-</td>
                    <td>14/02/2001</td>
                    <td>16/7/2022</td>
                    <td className="text-center">1</td>
                    <td></td>
                </tr>
            </tbody>
        </table>
    </div> 
   </> 
  )
}

export default UserList;
